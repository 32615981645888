export const theme = {
  colors: {
    bodyFont: "#000000",
    homeHardware: {
      primary: "#e4012b",
      hover: "#FDCA07",
      black: "#000000",
      link: "#FDCA07",
    },
  },
  breakpoints: {
    mobileSm: "499px",
    mobileMd: "768px",
    tablet: "1072px",
    desktop: "1200px",
  },
}
